<template>
  <div class="content-layout-fixer">
    <div v-if="isVisibleFinalQuestion && !isSuccessCertificate">
      <VueText class="final-quiz-header" weightLevel="1">Final Sınavı</VueText>
      <div class="quiz-wrapper" v-if="isQuizStarted && quizData.nextQuestion">
        <img
          v-if="!!quizData.nextQuestion.image"
          :src="quizData.nextQuestion.image"
          class="quiz-img"
          alt="quiz image"
        />
        <div class="quiz">
          <div class="question">
            {{ quizData.nextQuestion.question }}
            <span>{{ quizData.nextQuestion.priorty + '/' + quizData.questionCount }}</span>
          </div>
          <VueListView dir="column" class="option-list">
            <VueButton
              class="option"
              v-for="o in quizData.nextQuestion.options"
              :key="o.optionId"
              :isSingleLine="false"
              outlined
              :class="optionClass(o.optionId)"
              @click.prevent="() => selectOption(o.optionId)"
              >{{ o.option }}</VueButton
            >
          </VueListView>
        </div>
        <BrandButton @click="onSubmit" class="btn-answer" type="submit" :disabled="!selectedAnswer">
          {{ getButtonText }}
        </BrandButton>
      </div>
    </div>
    <div v-else>
      <div class="card-step-bar">
        <div class="step-bar">
          <div
            v-for="index in currentCategory.totalStep"
            :class="getStepClasses(index)"
            @click="handleClick(index)"
            :key="index"
          />
        </div>
      </div>
      <div v-if="isRosetteTask" class="card-bell-ring">
        <div class="bell-ring-wrapper">
          <img
            src="~@/assets/rosettes/rosette-academy-content-bell-ring.svg"
            alt="rosette bell ring"
            class="bell-ring"
          />
          <div class="bell-ring-text">AKTİF+ GÖREVİ</div>
        </div>
      </div>
      <div v-if="takeFinalExamText" @click="goToFinalQuiz" class="take-final-exam">
        <VueIcon
          class="icon-info"
          :width="icons.info.width"
          :height="icons.info.height"
          :iconName="icons.info.name"
          iconColor="#c60000"
        />
        <VueText style="margin: 5px 15px; font-size: 18px;  color:#c60000" weightLevel="1"
          >Final Sınavına Giriniz!</VueText
        >
      </div>
      <div class="video-banner">
        <swiper
          v-if="!isQuizStarted && slideVideoList"
          @slideChange="changeSwiperIndex"
          :options="sliderOptions"
          ref="swiperId"
        >
          <swiper-slide :key="video.id" v-for="video in slideVideoList">
            <div class="video-wrapper">
              <BrandVimeoPlayer
                class="video-wrapper"
                :id="video.id"
                :logger="{ interval: 5 }"
                ref="vimeoPlayer"
                :data="{
                  embedVideo: video.embedVideo,
                  videoId: video.id,
                  percentWatched: video.log && video.log.percent,
                  playbackPosition: video.log && video.log.stopTime,
                  title: video.title,
                  viewStatus: video.log && video.log.status,
                  isViewed: video.log && video.log.viewCount > 0,
                }"
                @timeUpdate="onProgress($event)"
                @end="onProgress($event, true)"
              />
            </div>
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next" />
          <div class="swiper-button-prev" slot="button-prev" />
        </swiper>
        <div
          :class="[{ active: nextButtonVisibilty }, 'quiz-arrow-btn']"
          @click="startQuiz()"
        ></div>
        <div v-if="!isQuizStarted && currentCategory.htmlText" class="description-wrapper">
          <VueText
            sizeLevel="6"
            weightLevel="3"
            class="description"
            v-html="currentCategory.htmlText"
          />
        </div>
        <div class="quiz-wrapper" v-if="isQuizStarted && quizData.nextQuestion">
          <img
            v-if="!!quizData.nextQuestion.image"
            :src="quizData.nextQuestion.image"
            class="quiz-img"
            alt="quiz image"
          />
          <div class="quiz">
            <div class="question">
              {{ quizData.nextQuestion.question }}
              <span>{{ quizData.nextQuestion.priorty + '/' + quizData.questionCount }}</span>
            </div>
            <VueListView dir="column" class="option-list">
              <VueButton
                class="option"
                v-for="o in quizData.nextQuestion.options"
                :key="o.optionId"
                :isSingleLine="false"
                outlined
                :class="optionClass(o.optionId)"
                @click.prevent="() => selectOption(o.optionId)"
                >{{ o.option }}</VueButton
              >
            </VueListView>
          </div>
          <BrandButton
            @click="onSubmit"
            class="btn-answer"
            type="submit"
            :disabled="!selectedAnswer"
          >
            {{ getButtonText }}
          </BrandButton>
        </div>
      </div>
      <div class="other-videos">
        <VueText level="1" class="headline">{{ currentCategory.name }}</VueText>
        <div class="video-card-wrapper">
          <div
            v-for="(video, key) in academyVideoList"
            @click="toSlide(key)"
            :class="video.class"
            :key="key"
            class="card"
          >
            <div class="card-inner">
              <img class="card-img" :src="video.thumbWithoutPlayButton" :alt="video.title" />
              <div class="card-content">
                <VueHeadline level="4" class="card-title">{{ video.title }}</VueHeadline>
                <VueText sizeLevel="4" class="card-desc">{{ video.description }}</VueText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BrandSurveyPopup
      style="align-items: center; backdrop-filter: blur(1px) !important;"
      :show.sync="isVisibleModulePopup"
    >
      <img
        @click="goToNextModule"
        :src="getAcademyPopupImage"
        alt="module-info"
        style="cursor: pointer;"
      />
    </BrandSurveyPopup>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueButton from '@/components/shared/VueButton/VueButton.vue';
import VueListView from '@/components/shared/VueListView/VueListView.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import { Entrepreneurship } from '@/services/Api/index';
import BrandSurveyPopup from '@/components/brand/Modals/BrandModal/BrandSurveyPopup.vue';
import vimeoUtils from '@/mixins/vimeoUtils.js';
import { ICON_VARIABLES } from '@/constants/component.constants';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';

export default {
  name: 'Entrepreneurship',
  components: {
    VueHeadline,
    VueText,
    VueIcon,
    swiper,
    swiperSlide,
    BrandButton,
    VueListView,
    VueButton,
    BrandVimeoPlayer,
    BrandSurveyPopup,
  },
  mixins: [vimeoUtils],
  data() {
    return {
      isVisibleModulePopup: false,
      isVisibleFinalQuestion: false,
      finalQuizModel: {},
      academyPopupImage: '',
      moduleData: {},
      currentCategory: {},
      isRosetteTask: false,
      slideVideoList: [],
      videoList: [],
      academyVideoList: [],
      activeSlideIndex: 0,
      quizData: {},
      finalQuizId: null,
      nextButtonVisibilty: false,
      isQuizStarted: false,
      isSuccessCertificate: false,
      answerResponse: null,
      selectedAnswer: null,
      submitedAnswer: false,
      totalViewingPercent: 0,
      takeFinalExamText: false,
      sliderOptions: {
        slidesPerView: 1,
        centeredSlides: true,
        resistance: true,
        resistanceRatio: 0.7,
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        onSlideChangeEnd: function() {
          this.onSwipe();
        },
      },
    };
  },
  computed: {
    getAcademyPopupImage() {
      return this.academyPopupImage ?? '';
    },
    icons() {
      return ICON_VARIABLES;
    },
    getButtonText() {
      if (!this.submitedAnswer) {
        return 'CEVAPLA';
      }
      return this.quizData.isLastQuestion ? 'BİTİR' : 'SIRADAKİ SORUYA GEÇ';
    },
  },
  async created() {
    if (this.$route.query.id) {
      await this.setAcademyDetail(this.$route.query.id);
      await Entrepreneurship.checkFinalQuiz(this.$route.query.parentId).then(res => {
        if (!res?.data?.Data) return;
        this.finishQuizModel = res?.data?.Data;
        this.finalQuizId = res?.data?.Data.finalQuizId;
        this.isSuccessCertificate = res.data.Data.isSuccessCertificate;
        this.takeFinalExamText = !this.isSuccessCertificate && !!this.finalQuizId;
      });

      this.$store.dispatch('app/setOverlayFunction', false);
    }
  },
  watch: {
    $route() {
      if (!this.$route.query.id) return;
      this.setAcademyDetail(this.$route.params.id);
    },
  },
  methods: {
    goToFinalQuiz() {
      this.isVisibleFinalQuestion = true;
      this.startQuiz();
    },
    goToNextModule() {
      this.isVisibleModulePopup = false;

      if (this.finishQuizModel.finalQuizId && this.finishQuizModel.nextModuleId) {
        this.startQuiz();
        this.isVisibleFinalQuestion = true;
      } else if (
        !this.finishQuizModel.finalQuizId &&
        this.finishQuizModel.isSuccessCertificate &&
        this.finishQuizModel.nextModuleId === 0
      ) {
        this.$router.push({ path: `${RoutesRoot.Secure.path}/${RoutesSecure.Certificates.path}` });
      } else if (!this.finishQuizModel.finalQuizId && !this.finishQuizModel.isSuccessCertificate) {
        const path = `${RoutesRoot.Secure.path}/${RoutesSecure.Entrepreneurship.path}`;

        const query =
          this.finishQuizModel.nextModuleId !== 0
            ? { parentId: this.$route.query.parentId, id: this.finishQuizModel.nextModuleId }
            : null;

        this.$router.push({ path, query });
        window.location.reload();
      } else if (
        this.finishQuizModel.finalQuizId &&
        !this.finishQuizModel.isSuccessCertificate &&
        this.finishQuizModel.nextModuleId === 0
      ) {
        this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Learn.path}`);
      }
    },
    handleClick(index) {
      const { completedStep, totalStep } = this.currentCategory;

      const isCompletedStep = completedStep >= index;
      const isCurrentSlide = this.activeSlideIndex === index - 1;

      if (isCompletedStep && index === totalStep) {
        this.startQuiz();
      } else if (isCompletedStep && !isCurrentSlide && index !== totalStep) {
        this.toSlide(index - 1);
      }
    },
    getStepClasses(index) {
      const completedStepCondition = this.currentCategory.completedStep >= index;
      const currentCondition = this.activeSlideIndex === index - 1;

      return {
        active: completedStepCondition,
        current: currentCondition,
        step: true,
      };
    },
    async setAcademyDetail(id, endFlag = false) {
      try {
        const response = await Entrepreneurship.getAcademyDetail(Number(id));
        const academyData = response?.data?.Data;

        if (!academyData?.detail) {
          console.warn('No academy detail data found');
          return;
        }

        this.updateAcademyState(academyData, endFlag);
      } catch (error) {
        console.error('Error fetching academy detail:', error);
      }
    },
    updateAcademyState(academyData, endFlag) {
      const { detail, isRosette } = academyData;

      this.currentCategory = detail.currentCategory;
      this.isRosetteTask = isRosette || false;

      if (!endFlag) {
        this.moduleData = detail;
        this.setCurrentState();
      }
    },
    async setCurrentState(isEnded) {
      let index = this.moduleData.videos.findIndex(i => !i.log || i.log.status === '1');
      this.videoList = this.moduleData.videos.map((i, j) => {
        return {
          class: index !== -1 && j > index ? 'passive' : '',
          ...i,
        };
      });

      this.academyVideoList = await this.getVideoThumbsAsync(this.videoList);

      if (
        this.currentCategory.completedStep === this.currentCategory.totalStep &&
        !isEnded &&
        !this.isQuizStarted
      ) {
        this.slideVideoList = this.videoList;
        this.$refs.swiperId.swiper.slideTo(0, 0);
      } else if (isEnded) {
        this.setAcademyDetail(this.$route.query.id, true);
        if (index !== -1) {
          this.slideVideoList = this.videoList.slice(0, index + 1);
        }
        if (this.activeSlideIndex === this.videoList.length - 1) {
          this.nextButtonVisibilty = true;
        }
      } else {
        this.isQuizStarted = false;
        if (index !== -1) {
          this.slideVideoList = this.videoList.slice(0, index + 1);
          setTimeout(() => {
            this.$refs.swiperId.swiper.slideTo(index, 0);
          }, 200);
        } else {
          this.isQuizStarted = true;
          this.activeSlideIndex = this.videoList.length;
          this.setQuizData();
        }
      }
    },
    startQuiz() {
      this.isQuizStarted = true;
      this.activeSlideIndex = this.videoList.length;
      this.answerResponse = null;
      this.nextButtonVisibilty = false;
      this.setQuizData();
      window.scrollTo(0, 0);
    },
    setQuizData(priorty) {
      this.selectedAnswer = null;
      Entrepreneurship.getQuizData({
        contentTypeId: this.finalQuizId ? +this.$route.query.parentId : +this.currentCategory.id,
        priorty,
      }).then(res => {
        if (!res?.data?.Data?.detail) return;
        this.quizData = res.data.Data.detail;
        document.querySelector('.main-inner-tab-bar').scrollTo(0, 0);
        window.scrollTo(0, 0);
      });
      if (priorty) {
        this.submitedAnswer = false;
      }
    },
    async checkFinalQuiz() {
      await Entrepreneurship.checkFinalQuiz(
        this.finalQuizId > 0 ? +this.$route.query.parentId : +this.currentCategory.id,
      ).then(res => {
        if (!res?.data?.Data) return;

        this.finishQuizModel = res?.data?.Data;
        this.academyPopupImage = res?.data?.Data?.image;
        this.finalQuizId = this.finishQuizModel.finalQuizId;
      });
    },
    onProgress(eventData, isEnded) {
      let log = {
        percent: eventData.percent,
        stopTime: eventData.position,
        isEnded: eventData.viewStatus,
      };
      this.moduleData.videos[this.activeSlideIndex].log = log;
      if (isEnded) {
        this.setCurrentState(true);
      }
    },
    selectOption(id) {
      this.selectedAnswer = id;
    },
    optionClass(id) {
      let optClass = '';
      if (!this.submitedAnswer) {
        this.selectedAnswer === id ? (optClass = 'active') : (optClass = '');
      } else {
        this.answerResponse && this.answerResponse.trueOptionId === id
          ? (optClass = 'disabled true')
          : this.answerResponse &&
            this.answerResponse.trueOptionId !== id &&
            id === this.selectedAnswer
          ? (optClass = 'disabled false')
          : (optClass = 'disabled');
      }
      return optClass;
    },
    async finishQuiz(request) {
      await Entrepreneurship.finishQuiz(request);
    },
    answerQuiz(request) {
      Entrepreneurship.answerQuiz(request).then(res => {
        if (!res?.data?.Data) return;
        this.answerResponse = res.data.Data;
      });
    },

    async onSubmit() {
      if (!this.answerResponse) {
        await this.submitAnswer();
      } else if (!this.quizData.isLastQuestion) {
        await this.moveToNextQuestion();
      } else {
        await this.finishQuizAndCheckResults();
      }
    },

    async submitAnswer() {
      this.submitedAnswer = true;
      const request = {
        surveyId: this.quizData.surveyId,
        questionId: this.quizData.nextQuestion.questionId,
        optionId: this.selectedAnswer,
        surveyTakenId: this.quizData.surveyTakenId,
      };
      const response = await Entrepreneurship.answerQuiz(request);
      this.answerResponse = response?.data?.Data;
    },

    async moveToNextQuestion() {
      this.answerResponse = null;
      this.setQuizData(this.quizData.nextQuestion.priorty);
    },

    async finishQuizAndCheckResults() {
      const request = {
        surveyId: this.quizData.surveyId,
        surveyTakenId: this.quizData.surveyTakenId,
      };
      await this.finishQuiz(request);
      await this.checkFinalQuiz();
      this.submitedAnswer = this.answerResponse.isCorrect;
      if (!this.answerResponse.isCorrect) {
        this.answerResponse = null;
      } else {
        this.isVisibleModulePopup = true;
      }

      setTimeout(() => {
        this.$router.push({ path: '/' });
      }, 2000);
    },

    toSlide(i) {
      if (this.activeSlideIndex === this.videoList.length) {
        this.activeSlideIndex = i;
      }
      if (this.isQuizStarted) {
        this.slideVideoList = this.videoList;
        this.isQuizStarted = false;
        setTimeout(() => {
          this.$refs.swiperId.swiper.slideTo(i, 0);
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        this.$refs.swiperId.swiper.slideTo(i, 0);
        document.querySelector('.main-inner-tab-bar').scrollTo(0, 0);
        window.scrollTo(0, 0);
      }
      let index = this.videoList.findIndex(i => !i.log || i.log.status === '1');
      if (index === -1 && i === this.videoList.length - 1) {
        this.nextButtonVisibilty = true;
      } else if (i !== this.videoList.length - 1) {
        this.nextButtonVisibilty = false;
      }
    },
    changeSwiperIndex() {
      let index = this.videoList.findIndex(i => !i.log || i.log.status === '1');
      this.activeSlideIndex = this.$refs.swiperId.swiper.activeIndex;
      if (index === -1 && this.activeSlideIndex === this.videoList.length - 1) {
        this.nextButtonVisibilty = true;
      } else if (this.activeSlideIndex !== this.videoList.length - 1) {
        this.nextButtonVisibilty = false;
      }
    },
    setDefaultState() {
      this.moduleData = {};
      this.currentCategory = {};
      this.slideVideoList = [];
      this.videoList = [];
      this.activeSlideIndex = 0;
      this.quizData = {};
      this.nextButtonVisibilty = false;
      this.isQuizStarted = false;
      this.answerResponse = null;
      this.selectedAnswer = null;
      this.submitedAnswer = false;
      this.finishQuizModel = {};
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  padding-left: 20px;
}
.final-quiz-header {
  margin: 25px 0;
  font-size: 30px;
  text-align: center;
  color: #c60000;
}
.take-final-exam {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
}
.headline {
  font-size: 18px;
  font-weight: 500;
}
.video-banner {
  position: relative;
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  .video-frame {
    position: relative;
    display: flex;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    overflow: hidden;
    &:before {
      content: '';
      display: block;
      padding-top: 56.25%;
    }
  }
}
.swiper-button-prev,
.swiper-button-next {
  height: 30px;
  margin-top: -15px;
}
.other-videos {
  margin-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;

  .video-card-wrapper {
    margin-top: 20px;

    .card {
      border: 1px solid #b4c2d3;
      margin-bottom: 20px;
      &.passive {
        pointer-events: none;
        .card-inner {
          background-color: #f6f5ee;
          .card-img {
            filter: grayscale(1);
          }
          .card-content {
            color: #5a5951;
          }
        }
      }
      .card-inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: #fff;
      }
    }
  }
}
.description-wrapper {
  padding-right: 20px;
  padding-left: 20px;
}
.description {
  border-bottom: 1px solid #b4c2d3;
  line-height: 1.22;
  margin-top: 20px;
  padding-bottom: 17px;
}
.link-btns {
  max-width: 600px;
  margin-top: 20px;
  .link-btn {
    max-width: 600px;
    margin-bottom: 20px;
    border: 1px solid #1e8fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #1e8fff;
    &:nth-child(1),
    &:nth-child(2) {
      background-image: linear-gradient(
        207deg,
        rgba(30, 143, 255, 1) -14%,
        rgba(30, 143, 255, 1) 88%
      );
    }
  }
}

.card-content {
  padding: 20px;
}
.card-img {
  width: 100%;
  filter: none;
}
.card-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.33;
  margin-bottom: 2px;
}
.card-desc {
  line-height: 1.43;
  color: #79838e;
  margin-bottom: 3px;
}
.card-step-bar {
  max-width: 650px;
  margin: 0 auto;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 43px;
}

.card-bell-ring {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
}
.bell-ring-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  background: #ef6b1c;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.bell-ring {
  height: 28px;
  border-radius: 50%;
  margin-left: -16px;
  border-color: red;
}
.bell-ring-text {
  padding-right: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 1.25;
}

.step-bar {
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  .step {
    height: 3px;
    background-color: #ebebeb;
    position: relative;
    flex: 1;
    &.active {
      cursor: pointer;
      background-color: #c60000;
      &:after {
        border-color: #c60000;
        background-color: #c60000;
        color: #fff;
      }
    }
    &.current {
      cursor: pointer;
      &:before {
        content: '';
        border: 2px solid;
        border-color: #fff !important;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 2px;
        width: 30px;
        height: 30px;
        z-index: 1;
      }
      &:after {
        width: 34px;
        height: 34px;
        border-radius: 17px;
        background-color: #c60000;
        border-color: #c60000;
      }
    }
    &:after {
      position: absolute;
      top: 50%;
      right: 0;
      width: 30px;
      height: 30px;
      border: 3px solid #ebebeb;
      border-radius: 20px;
      background-color: #fff;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 500;
      color: #ebebeb;
    }
    &:nth-child(1) {
      &:after {
        content: '1';
      }
    }
    &:nth-child(2) {
      &:after {
        content: '2';
      }
    }
    &:nth-child(3) {
      &:after {
        content: '3';
      }
    }
    &:nth-child(4) {
      &:after {
        content: '4';
      }
    }
    &:nth-child(5) {
      &:after {
        content: '5';
      }
    }
    &:nth-child(6) {
      &:after {
        content: '6';
      }
    }
    &:last-child {
      &:after {
        content: 'Quiz';
        width: 56px;
        right: -23px;
      }
      &.current {
        &:before {
          content: '';
          border: 2px solid;
          border-color: #fff !important;
          background-color: transparent;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 2px;
          width: 54px;
          height: 31px;
          z-index: 1;
          right: -21px;
          border-radius: 15px;
        }
        &:after {
          width: 58px;
          height: 34px;
        }
      }
    }
  }
}
.step-number {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.25;
  color: #1e8fff;
  margin-left: 12px;
}
.quiz-wrapper {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quiz {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;

  &-img {
    width: 100%;
  }
  .question {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 18px;
    display: flex;
    span {
      margin-left: 20px;
      font-weight: 500;
    }
  }
  .option {
    display: block;
    width: 100%;
    background-color: #fff;
    min-height: 60px;
    border-radius: 4px;
    border: solid 1px #79838e;
    margin-bottom: 10px;
    text-align: left;
    padding-left: 16px;
    font-size: 16px;
    font-weight: 500;
    &.active {
      color: #fff;
      background-color: #79838e;
      border-color: #79838e;
    }
    &.true {
      color: #fff;
      background-color: #6dd400;
      border-color: #6dd400;
    }
    &.false {
      color: #fff;
      background-color: #e5472d;
      border-color: #e5472d;
    }
  }
}
.btn-answer {
  border-radius: 0;
  font-weight: 500;
  height: 60px !important;
  width: 90% !important;
  background: #c60000;
}

.quiz-arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 27px;
  height: 30px;
  z-index: 8;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
  display: none;
  &.active {
    display: block;
  }
}
</style>
